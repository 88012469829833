 <template>
  <div style="height:100%">
    <div style="padding: 20px;height: 800px">
      <!-- 搜素框 -->
      <el-form :inline="true" :model="params" style="padding-top:20px;padding-left:20px;margin-bottom:20px;background-color:#fff;border-radius:8px" size="small">
        <el-form-item label="物料型号">
          <el-input v-model="params.modelName" placeholder="请输入物料型号"></el-input>
        </el-form-item>
        <el-form-item label="物料类型">
          <el-select v-model="params.typeId" placeholder="请选择物料类型">
            <el-option v-for="item in materialTypeOption" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="checkData" type="primary" size="mini">查询</el-button>
          <el-button type="info" @click="reset" size="mini">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div style="height:82%;background-color:#fff;border-radius:8px;padding-top: 8px;">
        <el-button type="primary" size="small" style="float:right;margin-top:20px;margin-right:20px;margin-bottom:20px" @click="add()" v-show="authorization('/home-admin/routineManage/material-model/add')">新增</el-button>
        <el-table :data="tableData" stripe style="padding:0 20px;" height="80%" size="small" :header-cell-style="{'background-color':'#84aace4a','color':'#343333','text-align':'center'}" :cell-style="{'text-align':'center'}">
          <el-table-column prop="modelName" label="物料型号"></el-table-column>
          <el-table-column prop="typeName" label="物料类型"></el-table-column>
          <el-table-column prop="price" label="单价"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat"></el-table-column>
          <el-table-column prop="orderIndex" label="排序" ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑" placement="top-end" v-show="authorization('/home-admin/routineManage/material-model/edit')">
                <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="edit(scope.row)" style="margin-right: 5px"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-end" v-show="authorization('/home-admin/routineManage/material-model/del')">
                <el-popconfirm title="确认删除吗？" @confirm="deleteModel(scope.row)">
                  <el-button content="删除" slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
                </el-popconfirm>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding-top:20px;height:40px;background-color:#fff;width: 100%;border-radius:8px;text-align:right">
          <el-pagination background :page-sizes="sizes" layout="total,sizes,prev,pager,next" :total="total" @current-change="handleCurrentChange" @size-change="sizeChange"></el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <el-dialog title="新增" :visible.sync="dialogFormAdd" width="700px" :close-on-click-modal="false">
      <el-form :model="MaterialModelArray" :rules="rules" ref="MaterialModelArray" label-width="160px" size="small" style="width:600px">
          <el-form-item label="物料型号" prop="modelName">
            <el-input v-model="MaterialModelArray.modelName"></el-input>
          </el-form-item>
          <el-form-item label="单价" prop="price">
            <el-input type="number" v-model="MaterialModelArray.price"></el-input>
          </el-form-item>
          <el-form-item label="物料类型" prop="typeId">
            <el-select v-model="MaterialModelArray.typeId" placeholder="请选择" style="width: 100%;">
              <el-option v-for="item in materialTypeOption" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop="orderIndex">
            <el-input-number v-model="MaterialModelArray.orderIndex" :min="0"></el-input-number>
          </el-form-item>
        </el-form>
      <div slot="footer" style="margin-top:50px">
        <el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addSubMit('MaterialModelArray')" size="mini">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹出层 -->
    <el-dialog title="编辑" :visible.sync="dialogFormEdit" width="700px" :close-on-click-modal="false">
      <el-form :model="MaterialModelArray" :rules="rules" ref="MaterialModelArray" label-width="160px" size="small" style="width:600px">
           <el-form-item label="物料型号" prop="modelName">
            <el-input v-model="MaterialModelArray.modelName"></el-input>
          </el-form-item>
          <el-form-item label="单价" prop="price">
            <el-input type="number" v-model="MaterialModelArray.price"></el-input>
          </el-form-item>
          <el-form-item label="物料类型" prop="typeId">
            <el-select v-model="MaterialModelArray.typeId" placeholder="请选择" style="width: 100%;">
              <el-option v-for="item in materialTypeOption" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="排序" prop="orderIndex">
            <el-input-number v-model="MaterialModelArray.orderIndex" :min="0"></el-input-number>
          </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top:50px">
        <el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="editSubMit('MaterialModelArray')" size="mini">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { authorization } from '../../../authorization/authorization'
export default {
  data() {
    return {
      dialogFormAdd: false,
      dialogFormEdit: false,
      tableData: [],
      materialTypeOption:[],
      params: {
        modelName: "",
        typeName: "",
        typeId:"",
        page: 1,
        size: 10,
      },
      MaterialModelArray: {
        id:"",
        modelName: "",
        typeId: "",
        price: '',
        orderIndex:0
      },
      rules: {
        modelName:[{ required: true, message: '请输入物料型号', trigger: 'blur' }],
        typeId:[{ required: true, message: '请选择物料类型', trigger: 'blur' }],
        price: [{ required: true, message: '请输入单价', trigger: 'blur' }],
        orderIndex:[{ required: true, message: '请输入排序号', trigger: 'blur' }]
      },
      total: 0,
      sizes: [10, 15],
    };
  },
  created() {
    this.queryMaterialModelList();
    this.queryMaterialTypeOption();
  },
  methods: {
    authorization,
    queryMaterialModelList(){//获取物料型号列表
      this.axios({
        method: "get",
        url: "/v1/web/material-model/select-material-model",
        params: this.params,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    queryMaterialTypeOption(){//获取物料类型下拉
      this.axios({
        method: "get",
        url: "/v1/web/material-type/select-all-material-type",
      }).then((res) => {
        console.log("数据"+res.data.data)
        this.materialTypeOption = res.data.data;
      });
    },
    add(){//新增弹出层
      this.dialogFormAdd = true;
      this.MaterialModelArray = {};
    },
    addSubMit(formName){//新增提交
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }else {
          this.axios({
            method: "post",
            url: "/v1/web/material-model/add-material-model",
            data: this.MaterialModelArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormAdd = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.queryMaterialModelList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      })
    },
    edit(row){//编辑弹出层
      this.dialogFormEdit = true;
      this.MaterialModelArray = row;
    },
    editSubMit(formName){//编辑提交
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }else {
          this.axios({
            method: "post",
            url: "/v1/web/material-model/update-material-model/" + this.MaterialModelArray.id,
            data: this.MaterialModelArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormEdit = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.queryMaterialModelList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      })
    },
    deleteModel(row){//删除
      this.axios({
        method: "delete",
        url: "/v1/web/material-model/delete-material-model/" + row.id,
        params: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.queryMaterialModelList();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    dateFormat:function(row){
      if(row.createTime !== null && row.createTime !== undefined){
        return moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.queryMaterialModelList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.queryMaterialModelList();
    },
    checkData() {
      this.queryMaterialModelList();
    },
    reset() {
      this.params = { page: 1, size: 10 };
      this.queryMaterialModelList();
    },
  },
};
</script>

<style scoped>

</style>
